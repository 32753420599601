'use strict';

Gri.module({
  name: 'selectbox-alpha',
  ieVersion: null,
  $el: $('.selectbox-alpha'),
  container: '.selectbox-alpha',
  fn: function () {
    this.$el.find('select').selectpicker();

    this.$el.on('changed.bs.select', function (e) {

      var selectval = e.target.value;
      var color = $(".colors-alpha");
      console.log('change');

      color.removeClass("active");
      $(".color-box").find("." + selectval).addClass("active"); 

    }); 
    $(".bootstrap-select").click(function () {
         $(this).addClass("open");
    });
  }
});
